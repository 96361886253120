.header {
  background-color: #8ca396;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header .logo img {
  height: 150px;
  display: flex;
}

.header .btn-custom {
  height: 40px;
  background-color: #8D8D8D;
  border-color: #8ca396;
  color: #fff;
  font-size: 12px;
}

.header .btn-custom:hover {
  background-color: #7a8b7a;

}

.header .icon-hover:hover {
  filter : saturate(20%);
}
.header .btn-station {
  height: 40px;
  background-color: #009234;
  color: #fff;
  font-size: 12px;
}
.header .btn-station:hover {
  background-color: #007a2b; /* Couleur légèrement plus foncée que #009234 */
}
.header nav ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.header .contact-info {
  display: flex;
  gap: 10px;
  color: white;
}

.header .contact-info a {
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .topnav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header .logo img {
    height: 80px; /* Ajuster la hauteur du logo pour les petits écrans */
  }

  .topnav .btn-custom, .topnav .btn-station {
    width: 100%;
    margin-bottom: 5px;
  }

  .header nav ul {
    display: none; /* Cacher les liens de navigation sur les petits écrans */
  }

  .header .burger-menu {
    display: block; /* Afficher le menu burger sur les petits écrans */
  }
}
