// src/components/Home/Home.scss
.home {
  .hero {
    background-color: #f5f5f5;
    text-align: center;
    padding: 50px;
    
    h1 {
      color: #333;
    }

    p {
      color: #666;
    }

    button {
      background-color: #8ca396;
      color: #fff;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      
      &:hover {
        background-color: #7a8b7a;
      }
    }
  }

  .about, .services {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin: 20px 0;
    
    h2 {
      color: #333;
    }

    p, ul {
      color: #666;
    }
  }
}
