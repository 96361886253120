.carousel-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0 15px; /* Ajoute du padding pour s'assurer qu'il y a de l'espace autour du carousel */
  max-width: 100%; /* Limite la largeur maximale du carousel à 80% */
  box-sizing: border-box; /* Assure que le padding est inclus dans la largeur totale */
}

.carousel {
  width: 100%;
  max-width: 1200px; /* Limite la largeur maximale du carousel */
}

.carousel-item img {
  width: 100%;
  height: auto; /* Définir une hauteur auto pour garder le ratio */
  max-height: 500px; /* Définir une hauteur maximum pour les images */
  object-fit: cover; /* Couper les images pour qu'elles remplissent le conteneur sans être étirées */
  transition: all 0.5s ease; /* Ajoute une transition pour lisser le changement de taille */
}

.carousel-caption {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: bottom;
  color: #fff;

  h3, p {
    margin: 0;
  }

  .btn-custom {
    margin-top: 10px;
    background-color: #8ca396;
    border-color: #8ca396;
    color: #fff;

    &:hover {
      background-color: #7a8b7a;
      border-color: #7a8b7a;
      color: #fff;
    }
  }
}

@media (max-width: 1200px) {
  .carousel-item img {
    max-height: 400px;
  }
}

@media (max-width: 992px) {
  .carousel-item img {
    max-height: 300px;
  }
}

@media (max-width: 768px) {
  .carousel-item img {
    max-height: 200px;
  }
  
  .carousel-caption {
    top: 50%;
    transform: translate(-50%, -60%);

    h3 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }

    .btn-custom {
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
    }
  }
}

@media (max-width: 576px) {
  .carousel-item img {
    max-height: 150px;
  }

  .carousel-caption {
    top: 40%;
    transform: translate(-50%, -70%);

    h3 {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.875rem;
    }

    .btn-custom {
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
    }
  }
}

@media (max-width: 320px) {
  .carousel-caption {
    top: 30%;
    transform: translate(-50%, -70%);

    h3 {
      font-size: 1,2rem;
    }

    p {
      font-size: 0.75rem;
    }

    .btn-custom {
      font-size: 0.625rem;
      padding: 0.5rem 1rem;
    }
  }
}
